const baseUrl = `${process.env.BASE_URL}public/img/cases`;

const cases = [
  {
    id: 1,
    cover: `${baseUrl}/解决方案封面_公司总指挥中心.png`,
    ttl: "公司总指挥中心",
  },
  {
    id: 2,
    cover: `${baseUrl}/解决方案封面_项目指挥中心.png`,
    ttl: "项目总指挥中心",
  },
  {
    id: 3,
    cover: `${baseUrl}/解决方案封面_全场景劳务实名制管理.png`,
    ttl: "全场景劳务实名制管理",
  },
  {
    id: 4,
    cover: `${baseUrl}/工人教育.png`,
    ttl: "工人教育",
  },
  {
    id: 5,
    cover: `${baseUrl}/解决方案封面_安全管理.png`,
    ttl: "安全管理",
  },
  {
    id: 6,
    cover: `${baseUrl}/解决方案封面_质量管理.png`,
    ttl: "质量管理",
  },
  {
    id: 7,
    cover: `${baseUrl}/解决方案封面_电子合同.png`,
    ttl: "电子合同",
  },
  {
    id: 8,
    cover: `${baseUrl}/定制化服务.png`,
    ttl: "定制化服务",
  },
];

export default cases;
