const baseUrl = `${process.env.BASE_URL}public/img/partners`;

const partners = ["one", "two", "three"].reduce((pV, cV) => {
  const list = [];
  let i = 0;
  while (i < 12) {
    list.push({
      id: `partner_${cV}_${i + 1}`,
      cover: `${baseUrl}/${cV}_${i + 1}.png`,
    });
    i += 1;
  }
  return pV.concat(list);
}, []);

export default partners;
