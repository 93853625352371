<template>
  <div class="page">
    <swiper
      @ready="onPageSwiper"
      @slideChange="onPageSlideChange"
      class="page_swp"
      :options="pageSwiperOptions"
    >
      <swiper-slide class="page_slide p1">
        <div class="main_box" flex="main:center cross:center">
          <video class="vd" ref="p1_bg_video" muted></video>
          <div class="cover" v-if="p1.videoCoverVisible"></div>
          <div
            :class="[
              'info_box',
              crtPageSlide === 1 && p1.videoEnd ? 'z-act' : '',
            ]"
          >
            <div class="ttl">工程管理数据驾驶舱</div>
            <div class="sub_ttl">
              实时掌控工程动态、高效决策、提效降本、规避风险
            </div>
            <div class="trial_btn" @click="showTrialModal">立即体验</div>
          </div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p7" flex="main:center cross:center">
        <div class="main_box">
          <div class="info_box">
            <div class="ttl">墨器星盘</div>
            <div class="sub_ttl">
              墨器星盘是工程管理数据驾驶舱，产品以劳务管理为切入点，实现管理人员与一线工人在线协同，并结合智能硬件，为企业提供多元化一站式的工程管理解决方案。墨器星盘，让工程管理焕然一新。
            </div>
            <div class="sub_ttl" :style="{ marginTop: '0.6rem' }">
              核心业务版块：<span class="u">数据星盘</span>、<router-link
                class="u"
                to="/solution/3"
                >劳务管理</router-link
              >、<router-link class="u" to="/solution/4">安全管理</router-link
              >、<router-link class="u" to="/solution/5">质量管理</router-link
              >、<span class="u">进度管理</span>、<router-link
                class="u"
                to="/solution/6"
                >工人教育</router-link
              >、<router-link class="u" to="/smart_construction"
                >智能硬件</router-link
              >
            </div>
            <div class="action" flex="dir:left">
              <div class="trial_btn" @click="showTrialModal">申请试用</div>
              <a
                class="trial_btn"
                :style="{ marginLeft: '0.6rem' }"
                href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
                target="_blank"
              >
                在线咨询报价
              </a>
            </div>
          </div>
          <div flex-box="1"></div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p9" flex="main:center cross:center">
        <div class="bg" />
        <div class="main_box" flex="main:justify cross:center">
          <div flex-box="1"></div>
          <div class="info_box">
            <div class="ttl">实时抓取真实数据</div>
            <div class="sub_ttl">
              功能贴合工程管理特性和流程，操作简单方便，基层易用好用。<br />实现管理人员与一线工人在线协同，在工作过程中自动产生并获取数据。
            </div>
            <div class="trial_btn" @click="showTrialModal">申请试用</div>
          </div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p2" flex="main:center cross:center">
        <div class="main_box">
          <div class="info_box">
            <div class="ttl">打破数据孤岛 实现数据互通共享</div>
            <div class="sub_ttl">
              保障数据安全、合规及其商业敏感性的前提下，运用区块链技术打造集团及总包模式，让组织之间快速实现管理协同、数据共享；<br />
              系统开放接口，各类智能硬件和企业已有子系统通过开放接口统一集成。
            </div>
            <div class="trial_btn" @click="showTrialModal">申请试用</div>
          </div>
          <div class="swp_wrap">
            <swiper
              @ready="onP2Swiper"
              @slideChange="onP2SlideChange"
              class="swp"
              :options="p2.options"
            >
              <swiper-slide class="sld" ref="p2_container">
                <video class="vd" ref="p2_bg_video" muted></video>
                <div class="cover" v-if="p2.videoCoverVisible"></div>
              </swiper-slide>
              <swiper-slide class="sld" v-for="item in 8" :key="item">
                <div :class="['cover', `c${item + 1}`]"></div>
              </swiper-slide>
            </swiper>
            <div class="prev" @click="onP2SwiperPrev"></div>
            <div class="next" @click="onP2SwiperNext"></div>
          </div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p3" flex="main:center cross:center">
        <div class="main_box" flex="main:justify cross:center">
          <div class="info_box">
            <div class="ttl">实时掌控工程动态<br />助您高效决策</div>
            <div class="sub_ttl">
              手机+PC端随时随地了解工程进展，免去反复沟通和大量会议，助力各级管理者高效决策。
            </div>
            <div class="trial_btn" @click="showTrialModal">申请试用</div>
          </div>
          <div class="phone_box">
            <video class="vd" ref="p3_video" loop muted></video>
            <div
              class="cover"
              ref="p3_container"
              v-if="p3.videoCoverVisible"
            ></div>
            <img
              class="framework"
              src="@/assets/img/iPhone12.png"
              alt="phone"
            />
          </div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p4" flex="main:center cross:center">
        <div class="main_box" flex="main:justify cross:center">
          <div flex-box="1"></div>
          <div class="info_box">
            <div class="ttl">智能预警 规避风险</div>
            <div class="sub_ttl">
              灵活设置异常指标，智能识别项目风险并及时预警，防范于未然。
            </div>
            <div class="trial_btn" @click="showTrialModal">申请试用</div>
          </div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p10" flex="main:center cross:center">
        <div class="main_box">
          <div class="info_box">
            <div class="ttl">专业服务 落地无忧<br />与客户一起成功！</div>
            <div class="sub_ttl">
              由专家顾问、研发工程师、客服团队构成专项小组提供全程服务，确保项目高效落地。<br />长期陪伴，主动迭代，助力客户成功。
            </div>
            <div class="action" flex="dir:left">
              <div class="trial_btn" @click="showTrialModal">申请试用</div>
            </div>
          </div>
          <div flex-box="1"></div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p5" flex="main:center cross:center">
        <div class="main_box">
          <div class="title">解决方案</div>
          <div class="swp_wrap">
            <swiper @ready="onP5Swiper" class="swp">
              <swiper-slide
                class="sld"
                v-for="(s, idx) in p5.slides"
                :key="idx"
              >
                <div class="group">
                  <router-link
                    :to="`/solution/${c.id}`"
                    class="case_item"
                    v-for="c in s"
                    :key="c.id"
                  >
                    <div
                      class="cover"
                      :style="{
                        backgroundImage: `url(${c.cover})`,
                      }"
                    ></div>
                    <div class="ttl">{{ c.ttl }}</div>
                  </router-link>
                </div>
              </swiper-slide>
            </swiper>
            <!-- <div class="prev" @click="onP5SwiperPrev"></div>
            <div class="next" @click="onP5SwiperNext"></div> -->
          </div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p6" flex="main:center cross:center">
        <div class="main_box">
          <div class="title">合作伙伴</div>
          <div class="info_box" flex="main:center">
            <div class="data_item">
              <div class="d">12,280</div>
              <div class="t">大型工程项目</div>
            </div>
            <div class="data_item">
              <div class="d">1,125</div>
              <div class="t">合作企业</div>
            </div>
          </div>
          <div class="swp_wrap">
            <swiper @ready="onP6Swiper" class="swp">
              <swiper-slide
                class="sld"
                v-for="(s, idx) in p6.slides"
                :key="idx"
              >
                <div class="group">
                  <div
                    class="brand_item"
                    :style="{
                      backgroundImage: `url(${c.cover})`,
                    }"
                    v-for="c in s"
                    :key="c.id"
                  ></div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="prev" @click="onP6SwiperPrev"></div>
            <div class="next" @click="onP6SwiperNext"></div>
          </div>
        </div>
        <div class="aside">
          <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p8" flex="main:center cross:center">
        <div class="main_box">
          <div class="title">从现在开始，让每个决策变简单</div>
          <div class="form_box">
            <div class="row" flex="dir:left">
              <div class="col">
                <div class="label">企业名称：</div>
                <input
                  class="ipt"
                  v-model="p8.companyName"
                  type="text"
                  placeholder="请输入"
                />
              </div>
              <div class="col">
                <div class="label">您的称呼：</div>
                <input
                  class="ipt"
                  v-model="p8.contact"
                  type="text"
                  placeholder="请输入"
                />
              </div>
            </div>
            <div class="row" flex="dir:left">
              <div class="col">
                <div class="label">职位：</div>
                <input
                  class="ipt"
                  v-model="p8.position"
                  type="text"
                  placeholder="请输入"
                />
              </div>
              <div class="col">
                <div class="label">手机号码：</div>
                <input
                  class="ipt"
                  v-model="p8.phone"
                  type="text"
                  placeholder="请输入"
                />
              </div>
            </div>
            <div class="row" flex="dir:left">
              <div class="col">
                <div class="label">需求描述：</div>
                <textarea
                  class="ipt"
                  :style="{
                    width: '65.4rem',
                    height: '8.5rem',
                    paddingTop: '0.7rem',
                    paddingBottom: '0.7rem',
                  }"
                  type="text"
                  v-model="p8.description"
                  placeholder="请输入"
                />
              </div>
            </div>
            <div class="trial_btn" @click="trial">申请体验</div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="page_slide p11" flex="main:center cross:center">
        <div class="main_box">
          <div flex="main:left">
            <div class="col_box" style="width: 35.2rem">
              <div class="title">合作与咨询</div>
              <div class="ttl">售前电话</div>
              <div class="tel">400-004-0686<span class="b">转2</span></div>
              <div class="desc">售前咨询、产品体验、业务合作</div>
              <div class="qrcode_wrap" flex="main:left cross:center">
                <img
                  class="p"
                  flex-box="0"
                  src="@/assets/img/smt.png"
                  alt="咨询顾问：沈女士"
                />
                <div flex-box="1">
                  <div class="t1">咨询顾问：沈女士</div>
                  <div class="t2">联系手机：199 6628 9230</div>
                  <div class="t3">联系邮箱：marketing@emodor.com</div>
                </div>
              </div>
            </div>
            <div class="col_box">
              <div class="title">服务与支持</div>
              <div class="ttl" style="background-color: #00c271">售后电话</div>
              <div class="tel">400-004-0686<span class="b">转1</span></div>
              <div class="desc">售后服务、问题解答</div>
              <div class="remark">
                <div class="t1">售后服务热线</div>
                <div class="t2">7*24小时服务，给您带来全新服务体验</div>
              </div>
            </div>
          </div>
          <div class="footer" flex="dir:left">
            <div class="entries" flex-box="1">
              <div class="row" flex="dir:left">
                <div class="label">快捷入口：</div>
                <div class="val">
                  <router-link to="/" class="link">官网首页</router-link>
                  <router-link to="/support" class="link">服务支持</router-link>
                  <router-link to="/smart_construction" class="link"
                    >智慧工地</router-link
                  >
                  <router-link to="/download" class="link">App下载</router-link>
                </div>
              </div>
              <div class="row" flex="dir:left">
                <div class="label">解决方案：</div>
                <div class="val">
                  <router-link to="/solution/1" class="link"
                    >公司指挥中心</router-link
                  >
                  <router-link to="/solution/2" class="link"
                    >项目指挥中心</router-link
                  >
                  <router-link to="/solution/3" class="link"
                    >劳务实名制</router-link
                  >
                  <router-link to="/solution/8" class="link"
                    >定制化服务</router-link
                  >
                  <router-link to="/solution/4" class="link"
                    >安全管理</router-link
                  >
                  <router-link to="/solution/5" class="link"
                    >质量管理</router-link
                  >
                  <router-link to="/solution/6" class="link"
                    >工人教育</router-link
                  >
                  <router-link to="/solution/7" class="link"
                    >电子合同</router-link
                  >
                </div>
              </div>
              <div class="row" flex="dir:left">
                <div class="label">关于我们：</div>
                <div class="val">
                  <a
                    class="link"
                    target="_blank"
                    href="https://www.emodor.com/about-us"
                    >关于我们</a
                  >
                  <a
                    class="link"
                    target="_blank"
                    href="https://www.emodor.com/about-us/responsibility"
                    >社会责任</a
                  >
                  <a
                    class="link"
                    target="_blank"
                    href="https://www.emodor.com/about-us/news"
                    >新闻动态</a
                  >
                  <a
                    class="link"
                    target="_blank"
                    href="https://www.emodor.com/about-us/government"
                    >政府实名制</a
                  >
                </div>
              </div>
              <div class="row" flex="dir:left">
                <div class="label">联系方式：</div>
                <div class="val">
                  <span class="txt"
                    >电话：400 004 0686<span
                      :style="{ color: '#4a4a4a', margin: '0 1rem' }"
                      >｜</span
                    >地址：广东省广州市黄埔区澳门青年人创新部落15楼</span
                  >
                </div>
              </div>
              <div class="row" flex="dir:left">
                <div class="label">网站备案：</div>
                <div class="val">
                  <span class="txt">Copyright Ⓒ 2022 墨斗科技 版权所有</span>
                  <a
                    class="link"
                    target="_blank"
                    href="https://beian.miit.gov.cn"
                    >粤ICP备18035980号</a
                  >
                </div>
              </div>
            </div>
            <div class="code" flex-box="0">
              <img class="p" src="@/assets/img/qrcode.jpg" alt="关注我们" />
              <div class="t">关注我们</div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="pagination">
      <div
        :class="['dot', crtPageSlide === item ? 'z-crt' : '']"
        @click="goPageSlide(item)"
        v-for="item in 11"
        :key="item"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cases from "@/data/cases";
import partners from "@/data/partners";
import req from "@/req";
import { isStrEmpty, isStrOverflow, checkPhone, validate } from "@/utils";

export default {
  name: "Home",
  data() {
    return {
      crtPageSlide: 1,
      pageSwiper: null,
      pageSwiperOptions: {
        direction: "vertical",
        mousewheel: { thresholdTime: 500 },
      },
      p1: {
        videoCoverVisible: true,
        videoEnd: false,
      },
      p2: {
        swiper: null,
        options: {
          slidesPerView: "auto",
          centeredSlides: true,
        },
        videoCoverVisible: true,
      },
      p3: {
        videoCoverVisible: true,
      },
      p5: {
        swiper: null,
        slides: cases.reduce(
          (pV, cV) => {
            const arr = pV[pV.length - 1];
            if (arr.length < 8) {
              arr.push(cV);
            } else {
              pV.push([cV]);
            }
            return pV;
          },
          [[]]
        ),
      },
      p6: {
        swiper: null,
        slides: partners.reduce(
          (pV, cV) => {
            const arr = pV[pV.length - 1];
            if (arr.length < 18) {
              arr.push(cV);
            } else {
              pV.push([cV]);
            }
            return pV;
          },
          [[]]
        ),
      },
      p8: {
        uploading: false,
        companyName: "",
        position: "",
        phone: "",
        contact: "",
        description: "",
      },
    };
  },
  mounted() {
    this.swiper = null;
    this.p1Bv = this.initP1();
    this.p2Bv = null;
    this.p3Bv = null;
    this.p2Timer = null;
  },
  beforeDestroy() {
    clearTimeout(this.p2Timer);
  },
  methods: {
    ...mapActions(["showTrialModal", "showTrialSuccModal"]),
    initP1() {
      const that = this;
      const bv = new window.Bideo();
      bv.init({
        videoEl: this.$refs.p1_bg_video,
        container: document.querySelector(".page_swp"),
        resize: true,
        isMobile: false,
        src: [
          {
            src: `${window.DIST_BASE_PATH}public/video/首页地球3_output.mp4`,
            type: "video/mp4",
          },
        ],
        onLoad() {
          that.p1.videoCoverVisible = false;
        },
      });
      bv.videoEl.addEventListener("timeupdate", () => {
        const { duration, currentTime } = bv.videoEl;
        if (duration - currentTime < 2) {
          that.p1.videoEnd = true;
        }
      });
      bv.videoEl.addEventListener("ended", () => {
        that.p1.videoEnd = true;
      });
      return bv;
    },
    initP2() {
      if (this.p2Bv) {
        return this.p2Bv;
      }
      const that = this;
      const bv = new window.Bideo();
      bv.init({
        videoEl: this.$refs.p2_bg_video,
        container: this.$refs.p2_container,
        // autoplay: false,
        resize: true,
        isMobile: false,
        src: [
          {
            src: `${window.DIST_BASE_PATH}public/video/演示-3_output.mp4`,
            type: "video/mp4",
          },
        ],
        onLoad() {
          that.p2.videoCoverVisible = false;
        },
      });
      bv.videoEl.addEventListener("ended", () => {
        that.onP2SwiperNext();
      });
      return bv;
    },
    initP3() {
      if (this.p3Bv) {
        return this.p3Bv;
      }
      const that = this;
      const bv = new window.Bideo();
      bv.init({
        videoEl: this.$refs.p3_video,
        container: this.$refs.p3_container,
        resize: true,
        isMobile: false,
        src: [
          {
            src: `${window.DIST_BASE_PATH}public/video/564_1224_output.mp4`,
            type: "video/mp4",
          },
        ],
        onLoad() {
          that.p3.videoCoverVisible = false;
        },
      });
      return bv;
    },
    onPageSwiper(swiper) {
      this.pageSwiper = swiper;
    },
    onPageSlideChange() {
      const { realIndex } = this.pageSwiper;
      this.crtPageSlide = realIndex + 1;
      if (this.p1.videoEnd) {
        this.p1Bv.videoEl.currentTime = 0;
      }
      this.p1.videoEnd = false;
      if (realIndex === 0) {
        this.p1Bv.videoEl.play();
      } else {
        this.p1Bv.videoEl.pause();
        this.p2Bv = this.initP2();
        this.p3Bv = this.initP3();
      }
      if (realIndex === 3 && this.p2.swiper.activeIndex === 0) {
        this.p2Bv.videoEl.play();
      } else {
        this.p2Bv.videoEl.pause();
      }
    },
    goPageSlide(item) {
      if (item === this.crtPageSlide) {
        return;
      }
      this.pageSwiper.slideTo(item - 1);
    },
    onP2Swiper(swiper) {
      this.p2.swiper = swiper;
    },
    onP2SlideChange() {
      const { realIndex } = this.p2.swiper;
      if (!this.p2Bv) {
        return;
      }
      clearTimeout(this.p2Timer);
      if (realIndex === 0) {
        this.p2Bv.videoEl.play();
      } else {
        this.p2Bv.videoEl.pause();
        this.p2Timer = setTimeout(() => {
          this.onP2SwiperNext();
        }, 4000);
      }
    },
    onP2SwiperPrev() {
      this.p2.swiper.slidePrev();
    },
    onP2SwiperNext() {
      const { isEnd } = this.p2.swiper;
      if (isEnd) {
        this.p2.swiper.slideTo(0);
      } else {
        this.p2.swiper.slideNext();
      }
    },
    onP5Swiper(swiper) {
      this.p5.swiper = swiper;
    },
    onP5SwiperPrev() {
      this.p5.swiper.slidePrev();
    },
    onP5SwiperNext() {
      this.p5.swiper.slideNext();
    },
    onP6Swiper(swiper) {
      this.p6.swiper = swiper;
    },
    onP6SwiperPrev() {
      this.p6.swiper.slidePrev();
    },
    onP6SwiperNext() {
      this.p6.swiper.slideNext();
    },
    resetP8Form() {
      this.p8.companyName = "";
      this.p8.position = "";
      this.p8.phone = "";
      this.p8.contact = "";
      this.p8.description = "";
    },
    checkData() {
      const { companyName, position, phone, contact, description } = this.p8;
      const validateRes = validate([
        { fn: isStrEmpty, opt: [companyName, "企业名称"] },
        { fn: isStrOverflow, opt: [companyName, 30, "企业名称"] },

        { fn: isStrEmpty, opt: [contact, "称呼"] },
        { fn: isStrOverflow, opt: [contact, 20, "称呼"] },

        { fn: isStrEmpty, opt: [position, "职位"] },
        { fn: isStrOverflow, opt: [position, 30, "职位"] },

        { fn: isStrEmpty, opt: [phone, "手机号码"] },
        { fn: checkPhone, opt: [phone, "手机号码"] },

        { fn: isStrEmpty, opt: [description, "需求描述"] },
        { fn: isStrOverflow, opt: [description, 100, "需求描述"] },
      ]);
      if (validateRes && validateRes.msg) {
        window.alert(validateRes.msg);
        return null;
      }
      return {
        companyName,
        position,
        phone,
        contact,
        description,
      };
    },
    trial() {
      const that = this;
      const data = this.checkData();
      if (!data) {
        return;
      }
      if (this.p8.uploading) {
        return;
      }
      this.p8.uploading = true;
      req.sys
        .applyRegisterCompany(JSON.stringify(data))
        .then((res) => {
          that.p8.uploading = false;
          if (res.code !== 0) {
            req.err.show(res.msg);
            return;
          }
          that.resetP8Form();
          that.showTrialSuccModal();
        })
        .catch((err) => {
          that.p8.uploading = false;
          req.err.show(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.page_swp {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.pagination {
  position: fixed;
  right: 1.25rem;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 1;
  padding: 0.1rem;

  .dot {
    width: 0.45rem;
    height: 0.45rem;
    opacity: 0.3;
    background-color: #fff;
    border-radius: 0.3rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    transition: transform 0.3s, opacity 0.3s;

    &:last-child {
      margin-bottom: 0;
    }

    &.z-crt {
      transform: scale(1.33);
      opacity: 1;
    }
  }
}

.page_slide {
  position: relative;
  overflow: hidden;

  .trial_btn {
    display: block;
    width: 10rem;
    height: 2.5rem;
    background: linear-gradient(
      180deg,
      rgba(0, 228, 255, 0.6),
      rgba(0, 228, 255, 0.3) 50%,
      rgba(0, 228, 255, 0.6)
    );
    border: 0.05rem solid;
    border-image: linear-gradient(
        180deg,
        rgba(0, 228, 255, 0.12),
        rgba(0, 228, 255, 0.72) 47%,
        rgba(0, 228, 255, 0.12)
      )
      2 2;
    border-radius: 0.2rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 2.5rem;
    cursor: pointer;
    text-decoration: none;
  }

  .aside {
    position: absolute;
    top: $navHeight;
    bottom: 0;
    left: 0;

    .pic {
      display: block;
      height: 100%;
    }
  }
}

.p1 {
  .main_box {
    position: absolute;
    left: 0;
    bottom: 0;
    top: $navHeight;
    width: 100%;

    .vd,
    .cover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      background-size: cover;
      height: 100%;
      width: 100%;
    }

    .cover {
      background-image: url("~@/assets/bg/首页地球.png");
    }

    .info_box {
      position: relative;
      transition: transform 0.6s, opacity 0.6s;
      transform: translate3d(0, 100%, 0);
      opacity: 0;

      &.z-act {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      .ttl {
        height: 4.5rem;
        font-size: 3.2rem;
        color: #fff;
        line-height: 4.5rem;
        text-align: center;
      }

      .sub_ttl {
        margin-top: 1.5rem;
        width: 34.5rem;
        height: 2.1rem;
        font-size: 1.5rem;
        color: #fff;
        line-height: 2.1rem;
        text-align: center;
      }

      .trial_btn {
        margin: 3.5rem auto 0;
      }
    }
  }
}

.p2 {
  background: url("../assets/bg/home_bg_2@3x.png") no-repeat center center;
  background-size: cover;

  .main_box {
    position: relative;
    z-index: 1;
    width: 65.4rem;
    padding-top: $navHeight;

    .info_box {
      .ttl {
        height: 3.75rem;
        font-size: 2.5rem;
        text-align: center;
        line-height: 3.75rem;
        color: #fff;
      }
      .sub_ttl {
        margin-top: 1.2rem;
        font-size: 1rem;
        text-align: center;
        color: #fff;
        line-height: 1.7rem;
      }
      .trial_btn {
        margin: 2rem auto 0;
      }
    }

    .swp_wrap {
      position: relative;
      margin-top: 3rem;

      .swp {
        width: 55rem;
        height: 25.5rem;
        margin: 0 auto;

        .sld {
          width: 45rem;
          transition: transform 0.3s;
          transform: scale(0.78) translate3d(0, 0, 0);
          background: linear-gradient(180deg, #ccd1dc, #a0a9b4);
          opacity: 0.5;

          &.swiper-slide-active {
            transform: scale(1) translate3d(0, 0, 0);
            opacity: 1;
            z-index: 1;
          }

          &.swiper-slide-prev {
            transform: scale(0.78) translate3d(45rem, 0, 0);
          }

          &.swiper-slide-next {
            transform: scale(0.78) translate3d(-45rem, 0, 0);
          }

          .vd,
          .cover {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            background-size: cover;
            background-repeat: no-repeat;
            height: 25rem;
            width: 44.6rem;
          }

          .c2 {
            background-image: url("~@/assets/bg/考勤_公司.png");
          }
          .c3 {
            background-image: url("~@/assets/bg/模式_地图.png");
          }
          .c4 {
            background-image: url("~@/assets/bg/施工日志_公司方案二.png");
          }
          .c5 {
            background-image: url("~@/assets/bg/安全管理_公司.png");
          }
          .c6 {
            background-image: url("~@/assets/bg/质量管理_公司.png");
          }
          .c7 {
            background-image: url("~@/assets/bg/工人教育_公司.png");
          }
          .c8 {
            background-image: url("~@/assets/bg/劳务费_公司.png");
          }
          .c9 {
            background-image: url("~@/assets/bg/工人画像_公司.png");
          }
        }
      }
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
    background: url("~@/assets/bg/ic_上一页_选中.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &.z-dsb {
      cursor: auto;
    }
  }

  .prev {
    left: -2rem;
  }

  .next {
    right: -2rem;
    transform: rotate(180deg);
  }
}

.p3 {
  background: url("../assets/bg/home_bg_3@3x.png") no-repeat center center;
  background-size: cover;

  .main_box {
    position: relative;
    z-index: 1;
    width: 65.4rem;
    padding-top: $navHeight;

    .info_box {
      .ttl {
        font-size: 2.5rem;
        color: #fff;
        line-height: 3.75rem;
      }
      .sub_ttl {
        margin-top: 1.2rem;
        width: 28rem;
        font-size: 1rem;
        color: #fff;
        line-height: 1.7rem;
      }
      .trial_btn {
        margin-top: 3rem;
      }
    }

    .phone_box {
      position: relative;
      width: 15.7rem;
      height: 32rem;
      box-shadow: 1.2rem 1.2rem 2.5rem 0rem rgba(0, 0, 0, 0.4);

      .vd,
      .cover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        background-size: cover;
        height: 30.6rem;
        width: 14.1rem;
      }

      .framework {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }
}

.p4 {
  background: #050812 url("../assets/bg/预警-4.gif") no-repeat left center;
  background-size: contain;

  .main_box {
    position: relative;
    z-index: 1;
    width: 65.4rem;
    padding-top: $navHeight;

    .info_box {
      width: 29rem;

      .ttl {
        font-size: 2.5rem;
        color: #fff;
        line-height: 3.75rem;
      }
      .sub_ttl {
        margin-top: 1.2rem;
        font-size: 1rem;
        color: #fff;
        line-height: 1.7rem;
      }
      .trial_btn {
        margin-top: 3rem;
      }
    }
  }
}

.p9 {
  background: #050812 url("../assets/bg/home_bg_9.png") no-repeat right center;
  background-size: cover;
  position: relative;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #050812 url("../assets/bg/背景.png") no-repeat left bottom;
    background-size: 100% auto;
  }

  .main_box {
    position: relative;
    z-index: 1;
    width: 65.4rem;
    padding-top: $navHeight;

    .info_box {
      width: 28rem;

      .ttl {
        font-size: 2.5rem;
        color: #fff;
        line-height: 3.75rem;
      }
      .sub_ttl {
        margin-top: 1.2rem;
        width: 28rem;
        font-size: 1rem;
        color: #fff;
        line-height: 1.7rem;
      }
      .trial_btn {
        margin-top: 3rem;
      }
    }
  }
}

.p5 {
  background: url("../assets/bg/home_bg_通用.png") no-repeat center center;
  background-size: cover;

  .main_box {
    position: relative;
    z-index: 1;
    padding-top: $navHeight;

    .title {
      font-size: 2.5rem;
      text-align: center;
      color: #fff;
      line-height: 3.75rem;
    }

    .swp_wrap {
      position: relative;
      margin-top: 3.3rem;

      .swp {
        width: 65.4rem;
        height: 27.5rem;
        margin: 0 auto;

        .group {
          overflow: hidden;
          width: 67rem;
        }
      }
    }
  }

  .case_item {
    float: left;
    margin-right: 1.15rem;
    margin-bottom: 2.5rem;
    width: 15.5rem;
    height: 12.4rem;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;

    .cover {
      display: block;
      width: 15.5rem;
      height: 10.3rem;
      background-size: cover;
    }

    .ttl {
      margin-top: 0.6rem;
      font-size: 1rem;
      text-align: center;
      color: #fff;
      line-height: 1.5rem;
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
    background: url("~@/assets/bg/ic_上一页_选中.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &.z-dsb {
      cursor: auto;
    }
  }

  .prev {
    left: -4.4rem;
  }

  .next {
    right: -4.4rem;
    transform: rotate(180deg);
  }
}

.p6 {
  background: url("../assets/bg/home_bg_8@3x.png") no-repeat center center;
  background-size: cover;

  .main_box {
    position: relative;
    z-index: 1;
    padding-top: $navHeight;

    .title {
      font-size: 2.5rem;
      text-align: center;
      color: #fff;
      line-height: 3.75rem;
    }

    .info_box {
      .data_item {
        width: 12.5rem;
        margin-right: 3.5rem;

        &:last-child {
          margin-right: 0;
        }

        .d {
          font-size: 2.5rem;
          font-weight: bold;
          text-align: center;
          color: #00e4ff;
          line-height: 3.5rem;
        }

        .t {
          margin-top: 0.25rem;
          font-size: 1rem;
          text-align: center;
          color: #ffffff;
          line-height: 1.7rem;
        }
      }
    }

    .swp_wrap {
      position: relative;
      margin-top: 4.5rem;

      .swp {
        width: 65rem;
        height: 15.5rem;
        margin: 0 auto;

        .group {
          overflow: hidden;
          width: 66rem;
        }
      }
    }
  }

  .brand_item {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 10rem;
    height: 4.5rem;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
    background: url("~@/assets/bg/ic_上一页_选中.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &.z-dsb {
      cursor: auto;
    }
  }

  .prev {
    left: -4.4rem;
  }

  .next {
    right: -4.4rem;
    transform: rotate(180deg);
  }
}

.p7,
.p10 {
  background: url("../assets/bg/home_bg_7@3x.png") no-repeat center center;
  background-size: cover;

  .main_box {
    position: relative;
    z-index: 1;
    width: 65.4rem;
    padding-top: $navHeight;

    .info_box {
      width: 28rem;

      .ttl {
        font-size: 2.5rem;
        color: #fff;
        line-height: 3.75rem;
      }
      .sub_ttl {
        margin-top: 1.2rem;
        width: 32rem;
        font-size: 1rem;
        color: #fff;
        line-height: 1.7rem;

        .u {
          color: #fff;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .action {
        margin-top: 2.2rem;
      }
    }
  }
}

.p10 {
  background-image: url("../assets/bg/home_bg_7.jpg");

  .main_box .info_box .sub_ttl {
    width: 28rem;
  }
}

.p8 {
  background: url("../assets/bg/home_bg_8@3x.png") no-repeat center center;
  background-size: cover;

  .main_box {
    position: relative;
    z-index: 1;
    width: 65.4rem;
    padding-top: $navHeight;

    .title {
      font-size: 2.5rem;
      color: #fff;
      line-height: 3.75rem;
    }

    .form_box {
      margin-top: 1.2rem;

      .row {
        margin-top: 1.8rem;

        .col {
          margin-right: 3rem;

          &:last-child {
            margin-right: 0;
          }
        }

        .label {
          opacity: 0.8;
          font-size: 0.9rem;
          color: #fff;
          line-height: 1.2rem;
        }

        .ipt {
          display: block;
          margin-top: 0.8rem;
          width: 31.2rem;
          height: 2.5rem;
          background-color: rgba(255, 255, 255, 0.08);
          border: 1px solid rgba(255, 255, 255, 0.16);
          border-radius: 0.1rem;
          padding-left: 1.2rem;
          padding-right: 1.2rem;
          color: #fff;
          box-sizing: border-box;
          font-size: 0.9rem;
        }
      }
    }

    .trial_btn {
      margin-top: 2.5rem;
    }
  }
}

.p11 {
  background: url("../assets/bg/home_bg_8@3x.png") no-repeat center center;
  background-size: cover;

  .main_box {
    position: relative;
    z-index: 1;
    width: 65.4rem;
    padding-top: $navHeight;

    .col_box {
      .title {
        font-size: 2.5rem;
        color: #fff;
        line-height: 3.75rem;
      }

      .ttl {
        margin-top: 2.25rem;
        width: 6rem;
        height: 1.85rem;
        background-color: #2574ff;
        border-radius: 2rem 2.5rem 2.5rem 0.1rem;
        font-weight: 600;
        font-size: 1rem;
        color: #fff;
        line-height: 1.85rem;
        text-align: center;
      }

      .tel {
        margin-top: 0.9rem;
        font-weight: 600;
        font-size: 1.8rem;
        color: #fff;
        line-height: 2.7rem;

        .b {
          font-weight: 600;
          font-size: 1.2rem;
          color: #00e4ff;
          line-height: 1.8rem;
          padding: 0.6rem 0 0 0.35rem;
        }
      }

      .desc {
        margin-top: 0.4rem;
        opacity: 0.7;
        font-weight: 400;
        font-size: 0.9rem;
        color: #fff;
        line-height: 1.2rem;
      }
    }

    .qrcode_wrap {
      margin-top: 3.1rem;

      .p {
        display: block;
        width: 5.7rem;
        height: 5.7rem;
        margin-right: 0.8rem;
      }

      .t1 {
        font-weight: 600;
        font-size: 1.2rem;
        color: #fff;
        line-height: 1.8rem;
      }

      .t2,
      .t3 {
        margin-top: 0.9rem;
        opacity: 0.7;
        font-weight: 400;
        font-size: 0.9rem;
        color: #fff;
        line-height: 1.2rem;
      }

      .t3 {
        margin-top: 0.6rem;
      }
    }

    .remark {
      margin-top: 4.9rem;

      .t1 {
        font-weight: 600;
        font-size: 1.2rem;
        color: #fff;
        line-height: 1.8rem;
      }

      .t2 {
        margin-top: 0.9rem;
        opacity: 0.7;
        font-weight: 400;
        font-size: 0.9rem;
        color: #fff;
        line-height: 1.2rem;
      }
    }

    .footer {
      margin-top: 5rem;
      border-top: 1px solid rgba(255, 255, 255, 0.2);

      .entries {
        margin-top: 1.75rem;

        .row {
          margin-bottom: 0.8rem;
          color: #eee;
          line-height: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          .label {
            width: 5.5rem;
            opacity: 0.3;
            font-size: 0.8rem;
            font-weight: bold;
            line-height: 24px;
          }

          .val {
            font-size: 0.7rem;

            .link,
            .txt {
              color: rgba(238, 238, 238, 0.6);
              text-decoration: none;
              margin-right: 1.5rem;

              &:last-child {
                margin-right: 0;
              }
            }

            .link {
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }

      .code {
        margin-top: 1.5rem;

        .p {
          display: block;
          width: 5.85rem;
          height: 5.85rem;
        }

        .t {
          margin-top: 0.5rem;
          opacity: 0.8;
          font-size: 0.7rem;
          color: #eee;
          line-height: 1rem;
          text-align: center;
        }
      }
    }
  }
}
</style>
